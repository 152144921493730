import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dr Alan Smith passed away in Cambridge on August 13, aged 80. Alan was a pioneer
in the early  days of plate tectonics, working with Edward Bullard and Jim
Everett at what was then the  Department of Geodesy and Geophysics at Cambridge
on the first computer-generated fits of the  continents around the Atlantic
Ocean. The first fit was published in the Proceedings of the Royal  Society in
1965, at a time when many respected authorities - such as Harold Jeffreys at
Alan's  Cambridge college - disputed that continental drift was at all possible. `}</p>
    <p>{`I was lucky enough to have Alan as one of my final year undergraduate
supervisors in 1968. I  learned a great deal about doing sound earth science
from his approach of posing penetrating  questions that would undermine dubious
ideas. And always with a wry sense of humour that made  him exceptionally good
company. We met again in later years and I attended a short course he  gave in
the early 1980s, showcasing the plate-reconstruction software 'Atlas' that I
have been  using since 1994. We met routinely on my visits to family in
Cambridge over the past 25 years  and enjoyed memorable walks together in the
English countryside and visits to Cambridge curry  houses. I will miss him
greatly. `}</p>
    <p>{`The picture below is from his obituary in The Daily Telegraph on August 24. To
see Alan in action  with his original computer-generated maps, follow the
following link: `}</p>
    <p>{`<`}<a parentName="p" {...{
        "href": "https://www.bl.uk/voices-of-science/interviewees/alan-smith/video/alan-smith-the-first-computer"
      }}>{`https://www.bl.uk/voices-of-science/interviewees/alan-smith/video/alan-smith-the-first-computer`}</a>{` aided-fit-of-the-continents>`}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      